import React from 'react';
import SharePost from '@/components/modules/SharePost';
import { removeHTMLTags, removeHypehenat, removePrefix } from '@/base/helpers/strings';
import type { HeroCenterContentVariantTypes } from './HeroCenterContentVariant.types';
import * as styles from './HeroCenterContentVariant.module.scss';

export default function HeroCenterContentVariant({
  date,
  theme = 'light',
  title,
  excerpt,
  subhead,
  category,
  children,
  background,
  overlapBody,
}: React.PropsWithChildren<HeroCenterContentVariantTypes>) {
  const description = subhead || excerpt;
  return (
    <div className={styles.container}>
      <section
        className={styles.wrapper}
        data-overlap-body={String(overlapBody)}
        data-theme={String(theme)}
        style={background ? { backgroundImage: `url(${background}/m/` } : {}}
      >
        <div className={styles.textContent}>
          {Array.isArray(category) && category.length ? (
            <p className={styles.category}>{removePrefix(removeHypehenat(category[0]), '/')}</p>
          ) : (
            <p className={styles.category}>
              {removePrefix(removeHypehenat(category), '/')}
              {date ? ` - ${date}` : ''}
            </p>
          )}
          <h1 className={styles.title}>{title}</h1>
          {description ? <p className={styles.excerpt}>{removeHTMLTags(description)}</p> : ''}
          <div className={styles.shareWrapper}>
            {title && <SharePost title={title} excerpt={excerpt} heading="Share" theme={theme} isCentered />}
          </div>
        </div>
      </section>
      {children && (
        <div className={styles.contentWrapper} data-overlap-body={String(overlapBody)}>
          {children}
        </div>
      )}
    </div>
  );
}
